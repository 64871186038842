.form-font {
	opacity: 0.5;
	color: #000000;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
	font-size: 12px;
}
.rc-steps-item-process .rc-steps-item-icon {
	background-color: #7ed321;
	border-color: white;
	border: 1px solid white;
}
.rc-steps-item-finish .rc-steps-item-icon {
	border-color: white;
	border: 1px solid white;
	background-color: #7ed321;
}
.rc-steps-item-finish .rc-steps-item-tail:after {
	background-color: #7ed321;
}
.container-register {
	padding-left: 150px !important;
	padding-right: 150px !important;
}
.container-register.mobile {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}
