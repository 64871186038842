/* calendar */
.react-datepicker {
	border: 0px;
	border-radius: 0px;
	width: 100%;
	font-size: 15px;
}
.react-datepicker__month-container {
	width: 100%;
}
.react-datepicker__navigation {
	margin-top: 19px;
}
.react-datepicker__navigation--previous {
	margin-left: 50px;
}
.react-datepicker__navigation--next {
	margin-right: 50px;
}
.react-datepicker__current-month {
	padding: 10px;
}

@media (min-width: 0) and (max-width: 1999px) {
	.react-datepicker__header {
		padding-left: 6px;
	}
}

@media (min-width: 1200px) {
	.react-datepicker__header {
		padding-left: 8px;
	}
}

.react-datepicker__header {
	background-color: white !important;
	border-bottom: 0px !important;
	padding-right: 7px;
}
.react-datepicker__day-names {
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	padding-left: 30px !important;
	padding-right: 30px !important;
}
.react-datepicker__day-names,
.react-datepicker__week {
	display: flex;
	justify-content: space-between;
	padding-top: 8px;
	padding-left: 25px;
	padding-right: 25px;
}
.react-datepicker__current-month {
	padding: 15px;
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
}
.react-datepicker__month {
	margin-top: 0px !important;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	padding-bottom: 15px;
}
.react-datepicker__day-name {
	font-weight: bold;
}
.react-datepicker__day {
	color: var(--main-color);
	width: 40px !important;
	padding: 4px;
}
.react-datepicker__day--selected {
	color: white !important;
}

.react-datepicker-wrapper {
	width: 100%;
}
.react-datepicker__input-container {
	width: 100%;
}
.react-datepicker__day--keyboard-selected {
	color: white !important;
}
