.my-modal {
	width: 50vw !important;
	max-width: 50vw !important;
}
.mlm {
	margin-left: -8px;
}
.form-font {
	opacity: 0.5;
	color: #000000;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
	font-size: 0.75rem;
}
