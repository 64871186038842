.custom-req-sent-heading {
	font-weight: 600;
	letter-spacing: -0.28px;
	line-height: 35px;
}

.custom-req-sent-request-to {
	font-weight: bold;
	letter-spacing: 0.5px;
	line-height: 12px;
}

.custom-req-sent-group-name {
	letter-spacing: -0.36px;
}
