.Toastify__toast-container {
	width: 350px !important;
}
.Toastify__toast-body {
	display: flex !important;
	justify-content: center !important;
}
.Toastify__toast-container--top-center {
	top: 3em;
}
