.custom-input label {
	text-transform: uppercase;
}

.custom-input label.invalid {
	color: red;
}

.custom-input input.invalid {
	border: 1px solid red;
}
