.browseGroupsCard {
	min-height: 400px;
}

.carousel .slide {
	background: transparent;
	border-radius: 2rem;
}

.carousel-root {
	width: 388px;
	min-height: 240px;
	border: none;
}

.slider-wrapper .axis-horizontal {
	border: none;
}

.carousel .carousel-status {
	color: black;
	margin-right: 6px;
	font-size: 12px;
	text-shadow: none;
	font-weight: 600;
}

.animated {
	background-color: #f8f9fa !important;
}

.carousel.carousel-slider .control-arrow {
	height: 75%;
	margin-top: 8%;
}

.carousel.carousel-slider .control-next.control-arrow {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.slider.animated:before {
	background-color: #f8f9fa !important;
}

.carousel.carousel-slider .control-prev.control-arrow {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.carousel .control-prev.control-arrow:before {
	border-right: 8px solid black;
}

.carousel .control-next.control-arrow:before {
	border-left: 8px solid black;
}
