.input-error {
	border-color: #e99083 !important;
}

.edit-profile .modal-content {
	height: calc(100% - 60px);
}

.edit-profile .modal-content .modal-body {
	border-radius: 10px;
}

.edit-profile .actions {
	box-shadow: none !important;
}
.edit-profile .actions .nav-item {
	box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%) !important;
}
