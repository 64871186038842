.cust-width {
	width: 20%;
}

.circle-green {
	color: var(--attendance-present);
}

.circle-red {
	color: var(--attendance-absent);
}

.circle-grey {
	color: var(--attendance-empty);
}

.circle-yellow {
	color: var(--attendance-excused);
}

.detailed-nav {
	font-size: 10px !important;
	text-transform: uppercase !important;
	color: #999999 !important;
	font-weight: bold !important;
	letter-spacing: 0.5px;
	line-height: 12px;
}

.detailed-nav-value {
	font-size: 13px !important;
	color: #000000 !important;
	font-weight: bold !important;
	letter-spacing: -0.14px;
	line-height: 15px;
}

.circle-size {
	font-size: 0.52rem !important;
}

.atten-month {
	background-color: #f5f5f5;
}

.atten-month-title {
	opacity: 0.5;
	font-weight: bold;
	letter-spacing: 0.5px;
	line-height: 12px;
	text-transform: uppercase;
}

.green-btn-cust {
	height: 44px;
	width: 166px;
	color: white !important;
	border-radius: 28px !important;
	background-color: #21af21 !important;
	border-color: #21af21 !important;
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.35);
	cursor: pointer;
}

.green-btn-cust:hover {
	height: 44px;
	width: 166px;
	color: white !important;
	border-radius: 28px !important;
	background-color: #21af21 !important;
	border-color: #21af21 !important;
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.35);
	cursor: pointer;
}

@media (min-width: 0px) and (max-width: 700px) {
	.session-metadata {
		width: 100% !important;
	}
}
@media (min-width: 701px) and (max-width: 1024px) {
	.session-metadata {
		width: 80% !important;
	}
}
@media (min-width: 1024px) and (max-width: 1500px) {
	.session-metadata {
		width: 60% !important;
	}
}

@media (min-width: 1500px) and (max-width: 3052px) {
	.session-metadata {
		width: 40% !important;
	}
}

.update-button {
	position: absolute;
	top: 110px;
	right: 55px;
}

.attendance-toast-body {
	color: var(--main-color);
}

.attendance-toast-progress {
	background: var(--main-color) !important;
}

.attendance-toast-progress-error {
	background: var(--main-alert) !important;
}

.Toastify__toast--error {
	background: white !important;
}

.attendance-toast-body-error {
	color: var(--main-alert) !important;
}
