.switch-small {
	display: inline-block;
	height: 18px;
	position: relative;
	width: 38px;
}

.switch-small input {
	display: none;
}

.slider-small {
	background-color: #ccc;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.4s;
}

.slider-small:before {
	background-color: white;
	bottom: 3px;
	content: '';
	left: 4px;
	position: absolute;
	transition: 0.4s;
	width: 12px;
	height: 12px;
}

input:checked + .slider-small {
	background-color: var(--main-color);
}

input:checked + .slider-small:before {
	transform: translateX(18px);
}

.slider-small.round {
	border-radius: 34px;
}

.slider-small.round:before {
	border-radius: 50%;
}
