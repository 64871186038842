.login {
	height: 100vh;
}
.text-grey {
	color: grey;
}
.form-font {
	opacity: 0.5;
	color: #000000;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
}
.image-login-container {
	width: 100%;
	height: 100%;
	background-image: url('../../images/img_login.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 65%;
}
.spacer {
	height: 80px;
}
