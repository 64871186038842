.font-10 {
	font-size: 10px !important;
}

.font-11 {
	font-size: 11px !important;
}

.font-13 {
	font-size: 13px !important;
}

.font-15 {
	font-size: 15px !important;
}

.font-17 {
	font-size: 17px !important;
}

.custom-background-color {
	background-color: #f2f2f2;
}

.badge-color-cust {
	background-color: #fff !important;
	border: 1px solid #e9e9e9 !important;
	font-weight: bold;
	letter-spacing: 0.3px;
	line-height: 12px;
}

.badge-color-cust:hover {
	background-color: rgb(240, 240, 240) !important;
	border: 1px solid #e9e9e9 !important;
}

.badge-success {
	letter-spacing: 0.3px;
	background-color: var(--main-color) !important;
}
