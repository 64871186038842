.popup {
	position: absolute;
	background: white;
	border: 1px solid var(--main-color);
	bottom: calc(100% + 3px);
	left: -5px;
	padding: 0.5rem;
	box-sizing: border-box;
	border-radius: 5px;
}

.popup:after {
	content: '';
	background: transparent;
	border: 10px solid transparent;
	border-top: 10px solid white;
	position: absolute;
	top: calc(100% - 2px);
	left: 3px;
}

.popup:before {
	content: '';
	background: transparent;
	border: 10px solid transparent;
	border-top: 10px solid var(--main-color);
	position: absolute;
	top: 100%;
	left: 3px;
}
