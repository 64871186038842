.custom-card {
	min-height: 320px !important;
	width: 300px !important;
	border-radius: 0.25rem !important;
}

.custom-card-heading {
	font-weight: 600;
	letter-spacing: -0.36px;
	line-height: 24px;
}

.custom-card-label {
	font-weight: bold;
	letter-spacing: 0.5px;
	line-height: 12px;
}

.custom-card-label-value {
	letter-spacing: 0.5px;
	line-height: 12px;
}

.custom-card-clinician-name {
	letter-spacing: -0.14px;
	line-height: 15px;
}

.custom-card-clinician-title {
	opacity: 0.5;
	color: #000000;
	line-height: 13px;
}
