.custom-modal-heading {
	font-weight: 600;
	letter-spacing: -0.33px;
	line-height: 36px;
}

.custom-search {
	border-top: 1px solid #ebeced;
	border-bottom: 1px solid #ebeced;
}

.custom-search-text {
	opacity: 0.3;
	color: #000000;
	letter-spacing: -0.17px;
	line-height: 18px;
}

.custom-group-list-heading {
	font-weight: 600;
	letter-spacing: -0.36px;
	line-height: 24px;
}

.custom-color-grey {
	color: #ebeced !important;
}

.custom-color-black {
	opacity: 0.3;
	color: #000000;
}

.custom-arrow {
	font-size: 25px !important;
}

.custom-click-style {
	cursor: pointer;
}
