.react-bootstrap-table {
	box-shadow: none !important;
}

.MuiPaper-root * {
	overflow: visible !important;
}

.attendance-table > .MuiPaper-root {
	overflow: visible !important;
	box-shadow: none !important;
}

.attendance-table .MuiTable-root {
	width: auto !important;
}

.attendance-table * {
	border: none !important;
	display: auto !important;
}

.attendance-table th {
	width: 88px !important;
	min-width: 88px !important;
	padding: 0.75rem !important;
	line-height: 1rem !important;
}

.attendance-table th div {
	font-weight: bold !important;
}

.attendance-table td {
	padding: 0.75rem !important;
}

.attendance-table .MuiTableRow-root {
	height: 72px !important;
}

.attendance-table .MuiTableRow-root.MuiTableRow-head {
	height: 64px !important;
}
