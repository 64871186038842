.custom-snippet {
	min-height: 232px !important;
	width: 330px !important;
	border-radius: 0.25rem !important;
	background: white;
	padding: 20px;
}

.custom-snippet-heading {
	font-weight: 600;
	letter-spacing: -0.36px;
	line-height: 24px;
}

.custom-snippet-label {
	font-weight: bold;
	letter-spacing: 0.5px;
	line-height: 12px;
}

.custom-snippet-label-value {
	letter-spacing: 0.5px;
	line-height: 18px;
}

.custom-snippet-clinician-name {
	letter-spacing: -0.14px;
	line-height: 15px;
}

.custom-snippet-clinician-title {
	opacity: 0.5;
	color: #000000;
	line-height: 13px;
}

select {
	border: 2px grey;
}
