.form-font {
	opacity: 0.5;
	color: #000000;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
	font-size: 12px;
}

.nav-tabs {
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 10px;
}

.nav {
	border-bottom: 0;
}

.nav-tabs .nav-link {
	padding: 20px;
}

.actions .nav-item {
	font-weight: bold;
	border-right: 0.2px solid rgb(226, 226, 226);
	color: #000000;
}

.active-mobile {
	font-weight: bold;
	padding: 20px;
	border-bottom: none !important;
	border-left: 2px solid #008dcb !important;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.actions .nav-link.active {
	font-weight: bold;
	padding: 20px;
	border-right: 1px solid rgb(226, 226, 226);
	border-bottom: 2px solid #008dcb !important;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.bg-mustard {
	background-color: #fbf6e3;
}

.form-guideline-font {
	font-size: 15px;
	letter-spacing: -0.08px;
	line-height: 21px;
}
