@media (min-width: 0px) and (max-width: 1024px) {
	.custom-card-container {
		padding-right: 0 !important;
	}
}
@media (min-width: 1025px) {
	.custom-card-container {
		padding: 0 2rem !important;
	}
}

@media (min-width: 0px) and (max-width: 1024px) {
	.custom-card-label-search-res {
		padding: 0 !important;
		font-weight: bold;
		letter-spacing: 1px;
	}
}
@media (min-width: 1025px) {
	.custom-card-label-search-res {
		padding: 0 3.5rem !important;
		font-weight: bold;
		letter-spacing: 1px;
	}
}

@media (min-width: 0px) and (max-width: 1024px) {
	.custom-card-label-search-res-parent {
		padding: 0 !important;
	}
}
@media (min-width: 1025px) {
	.custom-card-label-search-res-parent {
		padding: 0 15px !important;
	}
}

@media (min-width: 0px) and (max-width: 1024px) {
	.custom-card-label-search-my-bucket {
		padding: 0 !important;
		margin: 1rem 0 !important;
	}
}
@media (min-width: 1025px) {
	.custom-card-label-search-my-bucket {
		padding: 1rem 1.5rem !important;
		margin: 0 3rem !important;
	}
}

@media (min-width: 0px) and (max-width: 1024px) {
	.custom-card-label-search-my-bucket-parent {
		padding: 0 1.2rem !important;
		font-weight: bold;
		letter-spacing: 1px;
	}
}

.custom-bucket-container {
	padding: 1.2rem !important;
}

.custom-search {
	border-top: 1px solid #ebeced;
	border-bottom: 1px solid #ebeced;
}

.custom-color-black {
	opacity: 0.3;
	color: #000000;
}

.custom-search-text {
	color: #c8c8c8;
	font-weight: bold;
	letter-spacing: -0.34px;
	line-height: 25px;
}

.custom-bcard {
	border-radius: 0.25rem !important;
}

.cust-tnc-nav-heading {
	font-weight: 600;
	letter-spacing: -0.36px;
	line-height: 24px;
}
