.penta-select:hover {
	cursor: pointer;
}

.penta-select label {
	text-transform: uppercase;
}

.penta-select label.invalid {
	color: red;
}

.penta-select input.invalid {
	border: 1px solid red;
}
