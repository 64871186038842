.cust-datemodal-header {
	font-weight: 600;
	letter-spacing: -0.33px;
	line-height: 44px;
}

.cust-datemodal-label {
	font-weight: bold;
	letter-spacing: 0.5px;
	line-height: 12px;
}

.cust-datemodal-label-value {
	letter-spacing: 0.5px;
	line-height: 12px;
}

.cust-datemodal-clinician-name {
	letter-spacing: -0.14px;
	line-height: 15px;
}

.cust-datemodal-clinician-title {
	opacity: 0.5;
	color: #000000;
	line-height: 13px;
}
