.prog {
	height: 0.2rem !important;
}

.sticky {
	position: sticky;
	top: 100px;
	z-index: 2;
}

.footer {
	height: 50px;
	width: 100%;
	background: white;
	margin: 0;
}

.form-font {
	opacity: 0.5;
	color: #000000;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
	font-size: 12px;
}

.accept-input {
	width: 48.7% !important;
}

.radio:hover {
	background-color: var(--main-color-light);
}

.radio-active {
	background-color: var(--main-color) !important;
	border: var(--main-color) !important;
	color: white !important;
}

.toggle-options {
	width: 100px;
}

.card {
	border-radius: 0px;
}

.input-group-text {
	background-color: white !important;
	border-radius: 0px !important;
}

.signingModal {
	width: 1055px !important;
	height: 80vh !important;
	max-width: 80vw !important;
}

.my-modal {
	width: 80vw;
	height: 80vh;
	max-width: 80vw;
}

.signing-modal {
	width: 80% !important;
	height: 80% !important;
	max-width: 80% !important;
	max-height: 80% !important;
}
