.mtc-textbox {
	background-color: #00000099;
	position: absolute;
	top: 0;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
}

.mtc-avt-container {
	position: relative;
	margin-left: -5px;
}

.mtc-avatar {
	border: 2px solid white;
	border-radius: 50%;
}
