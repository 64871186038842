.code {
	border: 1px solid #dddddd;
}
.code-font {
	font-size: 2em;
}
.phone-number-font {
	font-size: 0.75em;
}
.error-font {
	color: #dc3545;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 13px;
}
.modal-header-custom {
	border-bottom: 0 !important;
}

.modal-header-custom-content {
	font-size: 1.875rem !important;
	font-weight: 600;
	letter-spacing: -0.33px;
	line-height: 36px;
}

.modal-body-custom-content {
	font-size: 1rem !important;
	letter-spacing: 0.53px;
	line-height: 26px;
}
.modal-if-you {
	font-size: 0.9375rem !important;
	letter-spacing: 0.5px;
	line-height: 18px;
}

.otp > div {
	border: 1px solid #dddddd;
	padding: 15px;
}
.number > div {
	border: 1px solid #dddddd;
}
