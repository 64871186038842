.clinician {
	font-size: 27px;
	font-weight: 600;
	letter-spacing: -0.28px;
	line-height: 30px;
}
.qual {
	font-size: 17px;
	line-height: 18px;
}
.heading-clinician-todos {
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 2px;
	line-height: 12px;
}
.name {
	font-size: 15px;
	letter-spacing: -0.14px;
	line-height: 15px;
}
.desc {
	font-size: 13px;
	line-height: 15px;
}
.loc {
	font-size: 13px;
	line-height: 15px;
}
.detail {
	font-size: 15px;
	line-height: 22px;
}
