.heading-settings {
	font-size: 30px;
	font-weight: 600;
	letter-spacing: -0.33px;
	line-height: 36px;
}
.title {
	font-size: 15px;
	line-height: 18px;
}
.role {
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0.7px;
	line-height: 10px;
	border-radius: 0px;
	padding: 4px;
}
.pen {
	padding: 2px;
	margin-top: 10px;
}
.option {
	padding: 20px 30px;
}
.btn-settings {
	width: 20px;
}
