.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	padding: 1px;
	/* border: 1px solid #ced4da; */
	align-self: center;
	display: flex;
	/* border: 0.5px solid #ced4da; */
	justify-content: center;
	/* width: 85px; */
}

.react-datepicker__time-box {
	width: 5rem !important;
}

.react-datepicker--time-only .react-datepicker__time-container {
	width: 200px !important;
}

.react-datepicker-wrapper {
	width: 100% !important;
}

.react-datepicker__input-container {
	width: 100% !important;
}
