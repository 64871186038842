.feedback-container {
	position: absolute;
	bottom: 0;
	right: 0;
	overflow: hidden;
	width: calc(100vw - 12px);
	max-width: 480px;
}

.feedback-container .card {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.feedback-form-enter,
.feedback-form-appear {
	transform: translateY(110%);
}

.feedback-form-enter-active,
.feedback-form-appear-active {
	transform: translateY(0%);
	transition: 0.3s ease-in-out;
}

.feedback-form-exit {
	transform: translateY(0%);
}

.feedback-form-exit-active {
	transform: translateY(110%);
	transition: 0.3s ease-in-out;
}
