.custom-bcard-dimensions {
	border: 0 !important;
}
.custom-bcard-ellipsis {
	width: 100%;
	text-align: start;
	overflow: hidden;
	text-overflow: ellipsis !important;
	white-space: nowrap;
}

.custom-bcard-heading {
	letter-spacing: -0.31px;
	line-height: 21px;
}
