.loading-parent {
	width: 100vw;
	height: 100vh;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	background-color: rgba(83, 82, 82, 0.692);
	z-index: 9999;
	min-height: calc(100% - (1.75rem * 2));
}
