@media (min-width: 0px) and (max-width: 1024px) {
	.custom-modal {
		max-width: 900px !important;
	}
}

@media (min-width: 1025px) {
	.custom-modal {
		max-width: 900px !important;
	}
}

.custom-sub-heading {
	font-weight: 600;
	letter-spacing: -0.36px;
	line-height: 24px;
}

.custom-bg-color {
	background-color: #f5f5f5 !important;
}

.custom-loa-text {
	letter-spacing: -0.32px;
	line-height: 27px;
}

.custom-loa-termcondition {
	font-style: italic;
	letter-spacing: -0.32px;
	line-height: 27px;
}

.custom-tag {
	font-weight: 600;
	letter-spacing: -0.32px;
	line-height: 27px;
}

.custom-no-radius {
	border-radius: 0 !important;
}

.custom-checkbox .custom-control-input:checked {
	background-color: rgba(16, 142, 200, 1) !important;
}

.custom-checkbox
	.custom-control-input:checked:focus
	~ .custom-control-label::before {
	box-shadow: 0 0 0 0;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0;
}

.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
	background-color: #fff;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0 !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: none !important;
}

.custom-relofInfo-text {
	letter-spacing: -0.32px;
	line-height: 27px;
}

.cust-loa-reason-for-absence-text {
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
}
