.heading-update-pass {
	font-size: 25px;
	font-weight: 600;
	letter-spacing: -0.33px;
	line-height: 36px;
}
.form-font {
	opacity: 0.5;
	color: #000000;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
	font-size: 10px;
}
.border-remove {
	border-bottom: 0 !important;
	padding: 5px 10px 0 10px !important;
	position: absolute;
	right: 0;
}

.modal-header .close {
	font-size: 30px;
}
