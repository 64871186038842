.nav-tabs {
	border-radius: 3px !important;
	background-color: #ffffff !important;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05) !important;
}
.nav {
	border-bottom: 0 !important;
}
.form-send-actions .nav-item {
	color: gray !important;
}
.form-send-actions .nav-link.active {
	border-color: #fff !important;
	border-bottom: 2px solid #008dcb !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}
textarea:focus,
select:focus,
button:focus,
input:focus {
	outline: none !important;
}
