.h-100vh {
	height: 100vh;
}
.form-font {
	opacity: 0.5;
	color: #000000;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 12px;
}
.error-font {
	color: #dc3545;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 13px;
}
.already-have-an-acco {
	letter-spacing: -0.08px;
	line-height: 15px;
}
.react-phone-number-input__icon--international {
	padding-bottom: 0.45rem;
}
.image-align-top .img {
	vertical-align: top !important;
}
.react-phone-number-input__input {
	height: initial !important;
	border-bottom: 0px !important;
}
.react-phone-number-input__country--native {
	align-items: initial !important;
}
.react-phone-number-input__icon {
	border: 0px !important;
}
.react-phone-number-input__country-select-arrow {
	margin-top: 0.6em !important;
}
.dropdown-content {
	background-color: white !important;
}

.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 28px;
}
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: var(--main-color);
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(21px);
	-ms-transform: translateX(21px);
	transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
