:root {
	--main-color: #007cbb;
	--main-color-hover: #1567b3;
	--main-color-light: #d8f0ff;
	--main-black: #000000;
	--main-white: #ffffff;
	--main-highlight: #00b4e1;
	--main-accent: #f08b1e;
	--main-accent-hover: #df7505;
	--main-grey: #555555;
	--main-light-grey: #afafaf;
	--main-success: #64a523;
	--main-alert: rgb(216, 83, 83);
	--secondary-highlight: #0092b1;
	--secondary-accent: #923a8b;
	--secondary-success: #a9ac0b;

	--attendance-present: #9bc076;
	--attendance-excused: #eeb659;
	--attendance-absent: #e37986;
	--attendance-empty: #eeeeee;
}

* {
	outline-color: var(--main-color) !important;
	font-family: 'Montserrat', sans-serif !important;
}

html,
body {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

body {
	background-color: #f5f5f5 !important;
}

button:disabled {
	cursor: not-allowed;
	pointer-events: all !important;
}

@media (min-width: 0) and (max-width: 1999px) {
	html,
	body {
		font-size: 16px !important;
	}
}

@media (min-width: 1200px) {
	html,
	body {
		font-size: 19px !important;
	}
}

input[type='checkbox']:active {
	color: red;
}

@media (min-width: 768px) {
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

.bg-gray {
	background-color: #f5f5f5;
}

.external-link {
	font-size: 14px;
	color: var(--main-light-grey);
	text-decoration: none;
}

.external-link:hover {
	color: var(--main-black);
	border-bottom: 2px solid var(--main-color);
	text-decoration: none;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.tab-link {
	transition: all 0.3s ease-in-out;
}

.tab-link:hover {
	color: var(--main-color) !important;
}

.nav-link.mtc-nav.active {
	font-weight: bold;
	border-bottom: 2px solid var(--main-color) !important;
}

.btn-color {
	background-color: var(--main-color) !important;
	border: 1px solid var(--main-color) !important;
}

.btn-color:hover {
	background-color: var(--main-color-hover) !important;
	border: 1px solid var(--main-color-hover) !important;
}

.btn-warn {
	background-color: var(--main-alert) !important;
	border: 1px solid var(--main-alert) !important;
}

.btn-warn:hover {
	background-color: rgb(224, 45, 45) !important;
	border: 1px solid rgb(224, 45, 45) !important;
}

.btn-action {
	background-color: var(--main-accent) !important;
	border: 1px solid var(--main-accent) !important;
	font-weight: bold;
	letter-spacing: 0.3px;
	line-height: 0.75rem;
	border-radius: 0 !important;
	text-transform: uppercase !important;
	color: white;
}
.btn-action:hover {
	background-color: var(--main-accent-hover) !important;
	border: 1px solid var(--main-accent-hover) !important;
	color: white;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
	border-radius: 5px;
}

.alert-color {
	color: red !important;
}
.alert-color:active {
	color: white !important;
}
.alert-color:hover {
	color: rgb(207, 20, 20) !important;
	text-decoration: none !important;
}

.link-color {
	color: var(--main-color) !important;
	text-decoration: none !important;
}
.link-color:active {
	color: white !important;
}
.link-color:hover {
	color: var(--main-color-hover) !important;
	text-decoration: none !important;
}

.filter-text-heading {
	font-size: 12px !important;
}

.text-main {
	color: var(--main-color) !important;
}

.text-dark {
	color: #000000 !important;
}

.remove-button {
	color: #f25555 !important;
}

.btn {
	border-radius: 0px;
}

.btn:focus {
	box-shadow: none !important;
}

.bg-success {
	background-color: #00db06 !important;
}

.font-8 {
	font-size: 0.5rem !important;
}

.font-9 {
	font-size: 0.5625rem !important;
}

.font-10 {
	font-size: 0.625rem !important;
}

.font-11 {
	font-size: 0.6875rem !important;
}

.font-12 {
	font-size: 0.75rem !important;
}

.font-13 {
	font-size: 0.8125rem !important;
}

.font-14,
.header-text {
	font-size: 0.875rem !important;
}

.font-15 {
	font-size: 0.9375rem !important;
}

.font-35 {
	font-size: 2.188rem !important;
}

.font-30 {
	font-size: 30px !important;
}

.font-16 {
	font-size: 16px !important;
}

.font-17 {
	font-size: 17px !important;
}

.font-18 {
	font-size: 18px !important;
}

.font-19 {
	font-size: 19px !important;
}

.font-20 {
	font-size: 20px !important;
}

.font-21 {
	font-size: 21px !important;
}

.font-22 {
	font-size: 22px !important;
}

.font-23 {
	font-size: 23px !important;
}

.font-24 {
	font-size: 24px !important;
}

.font-25 {
	font-size: 25px !important;
}

.font-26 {
	font-size: 26px !important;
}

.font-21 {
	font-size: 21px !important;
}

.text-cust-grey {
	color: #999999 !important;
}

.text-cust-grey-2 {
	columns: #666666 !important;
}

.circle-green {
	color: #9bc076 !important;
}

.circle-red {
	color: #e37986 !important;
}

.circle-grey {
	color: #eeeeee !important;
}

.circle-yellow {
	color: #eeb659 !important;
}

.hand {
	cursor: pointer;
}

.dropdown-menu {
	border: 0 !important;
	box-shadow:
		0 10px 20px rgba(0, 0, 0, 0.19),
		0 6px 6px rgba(0, 0, 0, 0.23);
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item.active *,
.dropdown-item:active * {
	background: var(--main-color) !important;
	color: white !important;
}

.dropdown-toggle::after {
	color: var(--main-color);
}

.shadow {
	border: 0 !important;
	box-shadow: 0px 0px 1px 1px rgba(230, 230, 230, 1);
}

@keyframes scroll {
	0% {
		max-height: 0;
	}

	100% {
		max-height: calc(100vh - 100px);
	}
}

.notification-menu {
	transform: translate3d(-46%, 68px, 0px) !important;
	width: 424px;
	padding: 0px;
	height: auto;
	max-height: calc(100vh - 100px);
	overflow-x: hidden;
	overflow-y: overlay;
	animation: ease-in-out scroll 0.5s;
}

.notification-menu:last-child {
	border-bottom: none;
}

.notification-item {
	width: 100%;
	border-bottom: 1px solid gainsboro;
}

.user-drop-down {
	top: 60px !important;
	right: 10px !important;
}

.user-drop-down.mobile {
	right: 0px !important;
	width: 100vw !important;
	max-width: 500px;
	overflow-x: hidden;
}

.nav-shadow {
	box-shadow: 0 6px 6px -6px #999;
}

.form-control {
	border-radius: 0 !important;
	color: #000000 !important;
}

.primary-btn-color {
	background-color: var(--main-color) !important;
	border: 1px solid var(--main-color) !important;
	font-weight: bold;
	letter-spacing: 0.3px;
	line-height: 0.75rem;
	border-radius: 0 !important;
	text-transform: uppercase !important;
}

.primary-btn-color:hover {
	background-color: var(--main-color-hover) !important;
	border: 1px solid var(--main-color-hover) !important;
}

.btn-color-cust,
.btn-color-cust:hover {
	background-color: #fff !important;
	border: 1px solid #e9e9e9 !important;
	font-weight: bold;
	letter-spacing: 0.3px;
	line-height: 0.75rem;
	border-radius: 0 !important;
	text-transform: uppercase !important;
}

.bl-0 {
	border-left: 0 !important;
}

.br-0 {
	border-right: 0 !important;
}

.bt-0 {
	border-top: 0 !important;
}

.bb-0 {
	border-bottom: 0 !important;
}

.custom-select {
	border-radius: 0 !important;
}

.cust-black-global {
	opacity: 0.5 !important;
	font-weight: 500 !important;
	letter-spacing: 1px !important;
}

.cust-black-global2 {
	letter-spacing: 0.5 !important;
	font-weight: 600 !important;
	line-height: 11px !important;
}

.nav-tabs .nav-link.active {
	color: #000000;
	opacity: 1;
}

.nav-link.active.mobile {
	font-weight: 600;
}

.cust-global-policies-border {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
	border-radius: 0.25rem !important;
}

.filter-nav {
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05) !important;
}

select:invalid {
	color: #b3b3b3 !important;
	border: 1px solid #ced4da !important;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #b3b3b3 !important;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: #b3b3b3 !important;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: #b3b3b3 !important;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: #b3b3b3 !important;
}

.v-h-center-outer {
	position: absolute;
	height: 80vh;
	width: 100vw;
}

.v-h-center-container {
	position: relative;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	width: fit-content;
}

.centered {
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	position: absolute;
}

.is-invalid-input:not(:focus) {
	border-color: #e99083 !important;
}

.form-error {
	font-size: 0.75rem;
	font-weight: bold;
	color: #ec5840;
}

.fa-info-circle {
	color: var(--main-color);
}

.request-button:active {
	background: var(--main-color) !important;
	color: white !important;
	outline: none !important;
}

.request-button:focus {
	background: var(--main-color) !important;
	color: white !important;
	outline: none !important;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
	scrollbar-width: thin !important;
	scrollbar-color: var(--main-color) rgb(236, 236, 236) !important;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
	width: 12px !important;
	height: 12px !important;
}
*::-webkit-scrollbar-track {
	background: rgb(236, 236, 236) !important;
}
*::-webkit-scrollbar-thumb {
	background-color: var(--main-color) !important;
	border-radius: 20px !important;
	border: 3px solid rgb(236, 236, 236) !important;
}

@media only screen and (max-width: 1025px) {
	body {
		overflow-y: overlay;
	}

	* {
		scrollbar-width: thin;
	}

	/* Works on Chrome/Edge/Safari */
	*::-webkit-scrollbar {
		width: 4px;
		height: 8px;
	}
	*::-webkit-scrollbar-track {
		display: none;
	}
	*::-webkit-scrollbar-thumb {
		background-color: var(--main-color);
		border-radius: 20px;
		border: 1px solid rgb(236, 236, 236);
	}
}

.double-arrow {
	display: inline;
}

.double-arrow:hover > .custom-arrow {
	border-color: var(--main-color-hover);
}

.custom-arrow {
	border: solid var(--main-color);
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	width: 8px;
	height: 8px;
	cursor: pointer;
}

.custom-arrow:hover {
	border-color: var(--main-color-hover);
}

.right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	margin-bottom: 1px;
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.up {
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}

.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.custom-arrow.up.large,
.custom-arrow.down.large,
.custom-arrow.left.large,
.custom-arrow.right.large {
	width: 16px;
	height: 16px;
}

.custom-arrow.up.white,
.custom-arrow.down.white,
.custom-arrow.left.white,
.custom-arrow.right.white {
	border-color: white;
}

.request-card {
	box-shadow:
		0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	margin: 0 auto;
	width: 100%;
	max-width: 480px;
	margin: 0.5rem auto;
}

.request-card:hover {
	box-shadow:
		0 14px 28px rgba(0, 0, 0, 0.25),
		0 10px 10px rgba(0, 0, 0, 0.22);
}

.mobile-button {
	box-shadow:
		0 10px 20px rgba(0, 0, 0, 0.19),
		0 6px 6px rgba(0, 0, 0, 0.23);
}

.modal-mobile {
	width: 100vw;
	max-width: 800px;
	margin: 0rem auto;
}

.modal.patient-profile {
	overflow: hidden;
}

.modal-open .modal {
	height: 100vh;
}

.ellipsis {
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}
