.browse-btn-color-cust {
	background-color: #fff !important;
	border: 1px solid #e9e9e9 !important;
}
.browse-btn-color-cust :hover {
	background-color: #fff !important;
	border: 1px solid #e9e9e9 !important;
}
.pri-btn-color {
	background-color: var(--main-color) !important;
	border: 1px solid var(--main-color) !important;
}
.pri-btn-color:hover {
	background-color: var(--main-color) !important;
	border: 1px solid var(--main-color) !important;
}
