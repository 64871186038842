.date-border {
	padding: calc(0.5rem + 1px) 0.75rem calc(0.5rem + 2px) 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0px !important;
}

/* calendar */
.react-datepicker {
	border: 0px;
	border-radius: 0px;
	width: 100%;
	font-size: 15px;
}

.react-datepicker-popper {
	z-index: 20;
}

.react-datepicker__day:hover {
	background: rgb(213, 242, 255);
}
.react-datepicker__day--selected:hover {
	background: var(--main-color) !important;
	color: white !important;
}

.react-datepicker__month-container {
	width: 100%;
}

.react-datepicker__navigation {
	margin-top: 19px;
}

.react-datepicker__navigation--previous {
	margin-left: 50px;
}

.react-datepicker__navigation--next {
	margin-right: 50px;
}

.react-datepicker__current-month {
	padding: 10px;
}

@media (min-width: 0) and (max-width: 1999px) {
	.react-datepicker__header {
		padding-left: 6px;
	}
}

@media (min-width: 1200px) {
	.react-datepicker__header {
		padding-left: 8px;
	}
}

.react-datepicker__header {
	background-color: white !important;
	border-bottom: 0px !important;
	padding: 0px !important;
}

.react-datepicker__day-names {
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
	display: flex;
	justify-content: space-between;
	padding-top: 8px;
	padding-left: 25px;
	padding-right: 25px;
}

.react-datepicker__current-month {
	padding: 15px;
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
}

.react-datepicker__month {
	margin: 0px !important;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
}

.react-datepicker__day-name {
	font-weight: bold;
}

.react-datepicker__day {
	color: var(--main-color) !important;
	width: 40px !important;
	padding: 4px;
}

.react-datepicker__day--selected {
	color: white !important;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container {
	width: 100%;
}

.react-datepicker__day--keyboard-selected {
	color: white !important;
}

.react-datepicker__header__dropdown {
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
}

.react-datepicker__year-read-view--down-arrow {
	top: 4px;
}

.react-datepicker__year-dropdown {
	background-color: white;
	color: var(--main-color) !important;
}

.react-datepicker__year-option {
	padding: 3px;
}

.placeholder-text {
	color: #b3b3b3 !important;
}

.react-datepicker__day--disabled {
	background: rgb(240, 240, 240);
	border-radius: 5px;
	color: grey !important;
}
.react-datepicker__day--disabled:hover {
	background: rgb(240, 240, 240);
	color: grey !important;
}

.minDate-highlight {
	position: relative;
}

.minDate-highlight::before {
	content: '';
	position: absolute;
	width: 5px;
	height: 100%;
	top: 0;
	left: 0;
	border: 2px solid var(--main-color);
	border-right: none;
}

.maxDate-highlight {
	position: relative;
}

.maxDate-highlight::after {
	content: '';
	position: absolute;
	width: 5px;
	height: 100%;
	top: 0;
	right: 0;
	border: 2px solid var(--main-color);
	border-left: none;
}

.disabled {
	background: gainsboro;
	cursor: not-allowed;
}
