.MuiPaper-root> {
	overflow: visible !important;
}

.Mui-disabled>span>svg {
	fill: var(--main-light-grey) !important;
}

.MuiTable-root .MuiSvgIcon-root {
	fill: var(--main-color) !important;
}

.MuiInputBase-input>div {
	margin-top: 5px !important;
}

.MuiPaper-elevation2 {
	box-shadow: none !important;
}

.MuiTablePagination-root {
	border: none !important;
}