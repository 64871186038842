.modal-content {
	height: 100%;
	width: 100%;
}

.demo-iframe-holder {
	height: 75vh;
	width: 100%;
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
	position: relative;
}

.demo-iframe-holder iframe {
	height: 100%;
	width: 100%;
}
