.filter-nav-mobile {
	padding: 0.5rem 0px;
	margin: 0px 0px;
}

.filter-drawer {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	z-index: 1000;
	transition: transform 0.3s ease-in-out;
}
