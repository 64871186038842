.tag-select,
.tag-select:hover,
.tag-select:focus,
.tag-select:active {
	background: white !important;
	color: black !important;
}

.tag-select::after {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(-50%, -50%);
}

.tag-select + .dropdown-menu {
	border: 1px solid #cfcfcf !important;
	border-top: none !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	margin-top: 0;
}
