.month-dropdown > button {
	background: white;
	color: var(--main-color);
	border: 1px solid #eeeeee;
	font-size: 0.75em;
	min-width: 320px;
}

.month-dropdown > button:hover,
.month-dropdown > button:active,
.month-dropdown > button:focus {
	background: white;
	border: 1px solid var(--main-color);
	color: var(--main-color);
}

.month-dropdown > div {
	width: 100%;
	text-align: center;
}

.month-item {
	font-size: 1.25em;
}

.month-item .active {
	background: var(--main-color);
	color: white;
}
