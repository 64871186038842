.present-pill {
	background-color: var(--attendance-present) !important;
	color: white;
}

.br-1 {
	border-right: 0.4px solid rgb(226, 226, 226);
}

.border-cust {
	border-top: 0 !important;
}

.secondary-btn {
	height: 44px;
	width: 166px;
	color: white !important;
	border-radius: 28px !important;
	background-color: #21af21;
	border-color: #21af21;
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.35);
	cursor: pointer;
}

.secondary-btn:hover {
	height: 44px;
	width: 166px;
	color: white !important;
	border-radius: 28px !important;
	background-color: #21af21;
	border-color: #21af21;
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.35);
	cursor: pointer;
}

.btn-postition {
	right: 20px;
	bottom: 200px;
}

.nav-margin {
	margin-left: 0.05rem !important;
	margin-right: 0.05rem !important;
}
