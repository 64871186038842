.avatar-circle {
	width: 40px;
	height: 40px;
	background-color: var(--main-color);
	text-align: center;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	position: relative;
}

.initials {
	position: relative;
	color: #ffffff;
	font-weight: bold;
}

.avatar-circle.big {
	width: 3000px;
	height: 80px;
	bottom: 10px;
}

.big .initials {
	font-size: 30px;
}
