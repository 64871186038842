@media screen and (min-width: 601px) {
	.tab-link-mobile {
		font-size: 14px;
	}
}

@media screen and (max-width: 600px) {
	.tab-link-mobile {
		font-size: 10px;
	}

	.tab-img {
		width: 20px;
		height: 20px;
	}
}
