.dropdown-indicator {
	margin-right: 10px;
}

.css-ixyimf-control {
	border-color: #b3b3b3 !important;
}

.css-ixyimf-control:hover {
	border-color: #b3d4fc !important;
	box-shadow: 0 0 0 4px #b3d4fc;
}
.css-1hwfws3 {
	padding: 2px 12px !important;
}
.css-151xaom-placeholder {
	color: #b3b3b3 !important;
}
