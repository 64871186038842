.heading {
	margin-bottom: 30px;
	margin-left: 0px;
	padding-left: 0px;
}

.my-to-dos {
	font-weight: bold;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 12px;
}

.options {
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 10px;
}

.p-12 {
	padding: 12px;
}

.option-style {
	font-weight: bold;
}

.option-base {
	border-left: 0.2px solid rgb(226, 226, 226);
	border-right: 0.2px solid rgb(226, 226, 226);
}

.option-selected {
	border-bottom: 2px solid #008dcb;
}

.container {
	margin-top: 10px;
}

.react-bootstrap-table {
	background: white;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.menu {
	content: url('../../images/ic_more.svg');
}

.nav-tabs {
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 10px;
}

.nav {
	border-bottom: 0;
}

.nav-tabs .nav-link {
	padding: 15px;
	color: rgba(0, 0, 0, 0.3);
}

.actions {
	border-radius: 0 !important;
}

.actions .nav-item {
	font-weight: bold;
	border-right: 0.2px solid rgb(226, 226, 226);
	color: #000000;
}

.actions .nav-link.active {
	font-weight: bold;
	padding: 15px;
	border-right: 0.2px solid rgb(226, 226, 226);
	border-bottom: 2px solid #008dcb;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top: 0;
}

.blank-filter {
	border-bottom: 1px solid #dee2e6;
}

.fa-filter {
	border-left: 1px solid rgb(226, 226, 226);
	border-bottom: 1px solid #dee2e6;
	border-radius: 0px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	background: url('../../images/ic_filter.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.table-header {
	font-size: 12px;
	letter-spacing: 1px;
}

.table thead th {
	border-bottom: 0px;
	vertical-align: inherit !important;
}

.head {
	font-size: 15px;
	letter-spacing: -0.14px;
	line-height: 17px;
}

.desc {
	font-size: 13px;
	line-height: 15px;
}

.user-drop-down .dropdown-item {
	padding-top: 15px;
	padding-bottom: 15px;
}
.btn-color-cust {
	background-color: #fff !important;
	border: 1px solid #e9e9e9 !important;
	font-weight: bold;
	letter-spacing: 0.3px;
	line-height: 12px;
	border-radius: 0 !important;
	text-transform: uppercase !important;
}

.btn-color-cust:hover {
	background-color: #fff !important;
	border: 1px solid #e9e9e9 !important;
	font-weight: bold;
	letter-spacing: 0.3px;
	line-height: 12px;
	border-radius: 0 !important;
	text-transform: uppercase !important;
}
